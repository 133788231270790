@import '~antd/dist/antd.variable.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.verifyCodeImg{
  width: 100%;
  height: auto;
}
/* 超出一行省略 */
.line1{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;max-width: 200px}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body{width: 100vw !important;height: 100vh;overflow: hidden;}
body::-webkit-scrollbar{display: none;}

// 自定义滚动条样式
.customScrollbar::-webkit-scrollbar{display: block !important;}
.customScrollbar::-webkit-scrollbar {  /* 滚动条整体部分 */
  width:0px;
}
.customScrollbar::-webkit-scrollbar-button { /* 滚动条两端的按钮 */
  width:10px;
  background-color: yellow;
}
.customScrollbar::-webkit-scrollbar:horizontal {
  height:6px;
  margin-bottom:2px;cursor: pointer;
}
.customScrollbar::-webkit-scrollbar-track {  /* 外层轨道 */
  border-radius: 10px;
}
.customScrollbar::-webkit-scrollbar-track-piece {  /*内层轨道，滚动条中间部分 */
  background-color: #EFEFEF;
  border-radius: 10px;
}
.customScrollbar::-webkit-scrollbar-thumb {  /* 滑块 */
  width:10px;
  border-radius: 5px;
  background: #C1C1C1;cursor: pointer;
}
.customScrollbar::-webkit-scrollbar-corner { /* 边角 */
  width: 10px;
  background-color: red;
}
.customScrollbar::-webkit-scrollbar-thumb:hover { /* 鼠标移入滑块 */
  background: #909090;cursor: pointer;
}


.ant-input:-internal-autofill-previewed {
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s !important;background-color: transparent;color: #fff !important;
}
.ant-input:-internal-autofill-selected {
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s !important;color: #fff !important;
}
.ant-message-success .anticon{color: @primary-color}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover{background: transparent !important;}
.ant-form-item-label > label{color: #666;}
.ant-spin.ant-spin-show-text .ant-spin-text{color: #999;}
// .ant-form-item-label{text-align: left;}
.asdTabs .ant-tabs-content,.asdTabs{height: 100%;}
.ant-layout.site-layout{position: relative;}
.asdTabs .ant-tabs-nav{position: absolute;left: 2px;top: 51px;right: 0;}
.closeTab{font-size: 12px;}
.ant-tabs-tab-with-remove .closeTab:hover{color: @primary-color;}
.asdTabs .ant-tabs-nav .ant-tabs-tab{border-bottom: 0;}
.asdTabs.ant-tabs-top > .ant-tabs-nav::before{display: none;}

.bf-container .public-DraftEditorPlaceholder-root div{font-size: 14px;color: #999;}
.public-DraftStyleDefault-ltr{font-size: 14px;}

.ant-upload{position: relative;}
.jdttt{position: absolute;width: 100%;left: 0;top: -7px;height: 32px;background-color: #fff;opacity: .8;}
.jdttt::after{display: block;content: '';width: 120px;height: 32px;position: absolute;left: 0;top: 0;cursor: no-drop;}


// .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{display: none;}

// 登录
.loginBox{width: 560px;margin: 0 auto;padding: 80px 60px 100px;position: fixed;left: 50%;margin-left: -280px;top: 50%;margin-top: -400px;border: 2px solid rgba(0,111,179,.4);background-color: rgba(0,41,81,.4);border-radius: 8px;}
.loginBox h1{font-size: 36px;font-weight: bold;color: #fff;position: relative;z-index: 1;margin-bottom: 100px;line-height: 48px;}
.loginBox h1::after{display: block;content: '';width: 360px;height: 24px;background: linear-gradient(to right,@primary-color,rgba(0,0,0,0));position: absolute;left: 0;bottom: -6px;z-index: -1;}
.loginBox .ant-input-affix-wrapper,.loginBox .ant-input{background-color: #1E2A39;border: 0;}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover{background-color: #1E2A39;}
.loginBox .ant-input-password-icon{color: #fff;font-size: 16px;}
.loginBox .ant-input-password-icon:hover{color: #fff;}
.loginBox .ant-input-affix-wrapper{height: 60px;}
.loginBox .ant-input-affix-wrapper .iconfont{font-size: 24px;color: #fff;}
.loginBox .ant-input{color: #fff;font-size: 16px;margin-left: 12px;}
.loginBox .flex{display: flex;align-items: flex-start;}
.loginBox .flex .ant-row{flex: 1;}
.loginBox .flex .code{width: 152px;height: 60px;border-radius: 2px;background-color: #000;margin-left: 8px;cursor: pointer;}
.loginBox .dlbtn{margin-top: 90px;width: 100%;line-height: 60px;height: 60px;padding: 0;font-size: 16px;font-weight: bold;}

.leftMenuToggle{font-size: 20px;margin-right: 14px;cursor: pointer;margin-top: -10px;}

.ant-menu-inline .ant-menu-item::after{left: 0;right: initial;}
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content{font-weight: bold;}
.ant-menu-sub.ant-menu-inline{background-color: #fff;}
.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{height: 54px;line-height: 54px;margin: 0;}
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{height: 60px;margin: 0;}
.ant-menu-inline{border-right: 0;}
.ant-dropdown-menu-item{text-align: center;}
.firr .ant-menu-title-content{font-weight: bold;}
.firr.ant-menu-item-selected .ant-menu-item-icon{color: @primary-color;}
.ant-layout-sider-children>.ant-menu{height: 88vh;overflow: auto;}
.ant-menu::-webkit-scrollbar{display: none;}
.ant-menu-submenu-selected .ant-menu-submenu-arrow{color: @primary-color;}

.ant-layout-content{padding: 24px;overflow: hidden;}
.ant-breadcrumb>span{display: flex;align-items: center;font-size: 20px;font-weight: bold;}
.ant-breadcrumb .iconfont{font-weight: normal;font-size: 28px;margin-right: 8px;}

.ant-modal-title::before{display: block;content: '';width: 4px;height: 20px;background-color: @primary-color;position: absolute;left: 0;}

.ant-pagination{padding: 16px 24px;}
.ant-pagination-total-text{font-size: 12px;color: #999;margin-right: 20px;}

.row_6{width: 6%;}
.row1{width: 10%;}
.row15{width: 15%;}
.row2{width: 20%;}
.row25{width: 25%;}
.row3{width: 30%;}
.row35{width: 35%;}
.row4{width: 40%;}
.row45{width: 45%;}
.row5{width: 50%;}
.row55{width: 55%;}
.row6{width: 60%;}
.row65{width: 65%;}
.row7{width: 70%;}
.row75{width: 75%;}
.row8{width: 80%;}
.row85{width: 85%;}
.row9{width: 90%;}
.row95{width: 95%;}
.row10{width: 100%;}
.rowFlex{flex: 1;}
.primary{background-color: @primary-color !important;}
.fu1{background-color: @success-color !important;}
.fu2{background-color: @warning-color !important;}
.fu3{background-color: @info-color !important;}
button.fu1{border-color: @success-color !important;}
button.fu2{border-color: @warning-color !important;}
button.fu3{border-color: @info-color !important;}
.marginl8{margin-left: 8px;}
.marginr12{margin-right: 12px;}
.marginr8{margin-right: 8px;}
.marginr4{margin-right: 4px;}
.flexAllCenter{display: flex;align-items: center;justify-content: center;max-width: 200px}
.flexCenter{display: flex;align-items: center;}
.flexColumn{display: flex;flex-direction: column;}
.flwp{display: flex;flex-wrap: wrap;align-content: start;}
.flex{display: flex;}
.flexEnd{justify-content: flex-end;}
.jt::before,.jt::after{display: block;color: #999;}
.jt::before{content: '\e618';margin-bottom: -10px;}
.jt::after{content: '\e61f';}
.jt.up::before{color: @primary-color;}
.jt.down::after{color: @primary-color;}
.cursor{cursor: pointer;user-select: none;}
.pubHui{color: #999;}
.pubHui:hover{border-color: #d9d9d9;color: #999;}
.flexWrap{display: flex;flex-wrap: wrap;margin-bottom: 0 !important;}
.flexWrap>div{width: calc(50% - 12px);margin-right: 24px;margin-bottom: 24px;}
.flexWrap>div:nth-child(2n){margin-right: 0;}
.paddleft98{padding-left: 98px;}
.color1{color: @primary-color !important;}
.color2{color: @success-color !important;}
.color3{color: @warning-color !important;}
.color4{color: @info-color !important; }
.item48{width: 49%;margin-right: 2%;}
.item48:nth-child(2n){margin-right: 0;}
.item32{width: 32%;margin-right: 2%;}
.item32:nth-child(3n){margin-right: 0;}

.pointer{cursor: pointer !important;}
.leftMenu{border-right: 1px solid #EFEFEF;}
.leftMenu .logo{display: flex;align-items: center;height: 90px;justify-content: center;}
.leftMenu .logo p{width: 40px;height: 40px;border-radius: 4px;background-color: @primary-color;font-size: 14px;font-weight: bold;color: #fff;display: flex;align-items: center;justify-content: center;}
.leftMenu .logo h1{font-size: 20px;margin-left: 12px;transition: width .4s;overflow: hidden;white-space: nowrap;font-weight: bold;}
.leftMenu .iconfont{font-size: 20px;}
.leftMenu .ant-menu-light .ant-menu-submenu-title:hover .iconfont,
.leftMenu .ant-menu-light .ant-menu-submenu-title:hover .ant-menu-title-content{color: @primary-color;}
.leftMenu.ant-layout-sider-collapsed .logo h1{width: 0;margin-left: 0;}

.ant-layout-header{padding: 0 25px;height: 90px;background-color: #fff;line-height: 16px;}
.ant-layout-header .topnav{display: flex;align-items: center;margin-top: 10px;margin-bottom: 5px;}
.ant-layout-header .zhut{cursor: pointer;margin-left: auto;}
.ant-layout-header .zhut .iconfont{font-size: 20px;}
.ant-layout-header .zhut p:last-child{font-weight: bold;margin-left: 6px;}
.ant-layout-header .zhut::after{display: block;content: '';width: 1px;height: 24px;background-color: #EFEFEF;margin: 0 24px;}
.ant-layout-header .avatar{display: block;width: 35px;height: 35px;border-radius: 100%;box-shadow: 0 0 16px #E8E8E8;margin-right: 16px;border: 4px solid #fff;}
.ant-layout-header .ant-breadcrumb{margin-right: auto;}
.ant-layout-header .ant-dropdown-trigger{cursor: pointer;}
.ant-layout-header .ant-dropdown-trigger p{font-weight: bold;max-width: 90px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}

// 主题配色
.huibtn{background-color: #EFEFEF;border-color: #EFEFEF;color: #999;}
.huibtn:hover{border-color: #EFEFEF;color: #999;background-color: #EFEFEF;}
.setColorBox .cont{display: flex;}
.setColorBox .btnbox{display: flex;align-items: center;justify-content: flex-end;}
.setColorBox .btnbox button{margin-right: 8px;}
.setColorBox .ltop{width: 900px;border-radius: 2px;border: 1px solid #EFEFEF;background-color: #F8F8F8;box-shadow: 0 0 32px #E8E8E8;display: flex;}
.setColorBox .ltop .zuo{width: 180px;border-right: 1px solid #EFEFEF;padding-right: 20px;padding-top: 20px;background-color: #fff;}
.setColorBox .ltop .flexCenter p{height: 16px;background-color: #EFEFEF;}
.setColorBox .ltop .zuo>div{margin-bottom: 20px;}
.setColorBox .ltop .you{flex: 1;}
.setColorBox .ltop .neir{padding: 20px;}
.setColorBox .ltop .exap{background-color: #fff;margin-top: 16px;padding: 4px 16px 24px;}
.setColorBox .lbot{display: flex;align-items: center;margin: 30px 0 16px;}
.setColorBox .lbot>p{width: 24px;height: 24px;border-radius: 2px;background-color: @primary-color;color: #fff;display: flex;align-items: center;justify-content: center;margin-left: 48px;font-size: 14px;cursor: pointer;}
.setColorBox .lbot>p:first-child{margin-left: initial;margin-right: 48px;}
.setColorBox .lbot .kkxbox{white-space: nowrap;width: 756px;overflow: hidden;}
.setColorBox .lbot .kkxbox::-webkit-scrollbar{display: none;}
.setColorBox .lbot .kkx{width: 244px;height: 116px;border-radius: 2px;border: 1px solid #EFEFEF;margin-right: 12px;padding: 20px 24px;display: inline-block;cursor: pointer;}
.setColorBox .lbot .kkx>p{color: #666;margin-bottom: 12px;}
.setColorBox .lbot .kkx .flexCenter p{width: 40px;height: 40px;border-radius: 2px;text-align: center;line-height: 40px;color: #fff;margin-right: 12px;}
.setColorBox .lbot .kkx .flexCenter p:last-child{margin-right: 0;}
.setColorBox .ant-carousel{width: 756px;}
.setColorBox .ant-carousel .slick-list .slick-slide{transition: .4s;cursor: pointer;}
.setColorBox .kkx,
.setColorBox .kkx:hover{border-color: @primary-color;}
.setColorBox .right{margin-left: 60px;flex: 1;}
.setColorBox .right .inpt{height: 40px;border-radius: 2px;border: 1px solid #E6E6E6;display: flex;align-items: center;padding: 0 10px;cursor: pointer;}
.setColorBox .right .inpt p{color: #666;margin-right: 6px;}
.setColorBox .right .inpt input{display: block;flex: 1;}
.setColorBox .right .inpt span{display: block;width: 20px;height: 20px;border-radius: 2px;}
.setColorBox .right>div{margin-top: 24px;}
.setColorBox .right>div>p{color: #666;width: 80px;}
.colorTool .ant-tooltip-inner{background-color: transparent;padding: 0;width: fit-content;}
.colorTool .ant-tooltip-arrow-content{background-color: #fff;}


.bgbai{height: calc(100% - 52px);border-radius: 2px;background-color: #fff;overflow: hidden;margin-top: 20px;display: flex;flex-direction: column;}
.pubTit{font-weight: bold;color: #000;padding: 22px 0;display: flex;align-items: center;}
.pubTit::before{display: block;content: '';width: 4px;height: 20px;background-color: @primary-color;margin-right: 20px;}
.pubList{height: 0;flex: auto;margin: 0 24px;overflow: scroll;}
.pubList::-webkit-scrollbar{display: none;}
.pubList .flex{display: flex;align-items: center;min-height: 60px;padding: 14px 0;}
.pubList .flex>p{padding: 0 10px;text-align: center;color: #666;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;line-height: 18px;}
.pubList .head p{color: #999;}
.pubList .flex:nth-child(2n){background-color: #F8F8F8;}
.pubSelt{background-color: #fff;border-radius: 2px;}
.pubSelt.borderbai>div{border-color: #fff !important;}
.pubSelt .iconfont{font-size: 12px;color: #333;}
.pubInpt{width: 240px;height: 32px;border-radius: 2px;background-color: #fff;}
.pubConfirm .ant-modal-body{padding: 24px 32px;}
.pubConfirm .ant-modal-confirm-title{display: flex;align-items: center;}
.pubConfirm .ant-modal-confirm-content{margin-left: 44px;color: #666;}
.pubConfirm .ant-modal-confirm-btns{margin-top: 40px;}
.borderbai{border-color: #fff;}
.pubbtnbox p{color: @primary-color;display: flex;align-items: center;cursor: pointer;}
.pubbtnbox p::after{display: block;content: '';width: 1px;height: 10px;background-color: #ddd;margin: 0 12px;}
.pubbtnbox p:last-child::after{display: none;}

.pubList .ant-table-thead > tr > th{background-color: #fff;border-bottom: 0;}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{display: none;}
.pubList .ant-table-tbody > tr > td{border-bottom: 0;background-color: #f8f8f8;}
.pubList .ant-table-tbody > tr:nth-child(2n) > td{background-color: #fff;}

// .editPwdBox .editMenu .ant-form-item-explain-error{padding-left: 70px;}
.editPwdBox>div{margin-bottom: 24px;}
.editPwdBox>div.btnbox:last-child{margin: 50px 0 0;}
.editPwdBox>div p{color: #666;font-size: 14px;width: 72px;}
.editPwdBox div input{flex: 1;}
.editPwdBox .avatar{display: block;width: 100px;height: 100px;border-radius: 100%;}
.uploadAvatar{display: block;width: fit-content;margin: 0 auto;cursor: pointer;}
.uploadAvatar .avatar+p{font-size: 14px;color: #999;text-align: center;margin: 10px auto 30px;}
.checklist{align-items: flex-start;}
.checklist p{width: 110px !important;}
.checklist .ant-checkbox-group{flex: 1;/*margin-left: -126px;margin-bottom: -10px;*/}
.checklist .ant-checkbox + span{color: #666;width: 110px;}
.checklist .ant-checkbox-group-item{margin-right: 0;}
// .checklist .ant-checkbox-group-item:first-child{margin-left: 126px;}
.checklist .ant-checkbox-wrapper{margin: 5px 0;}

.menuListbox{overflow-x: auto;}
.menuListbox::-webkit-scrollbar{display: block;}
.menuListbox>div{min-width: 1360px;}
.menuListbox .sjx{transform: rotate(-90deg);cursor: pointer;transition: .4s;}
.menuListbox .zii{color: #666;padding-left: 4px;}
.menuListbox .sjx.xia{transform: rotate(0);}
.menuListbox .flex:nth-child(2n){background-color: transparent;}
.menuListbox .flex:hover{background-color: #F8F8F8;}
.menuListbox .head.flex:hover{background-color: transparent;}


.three{height: 0;overflow: hidden;transition: .4s;}
.sec{height: 0;overflow: hidden;transition: .4s;}
.sec.open{height: 60px;}

.fontSize28 .iconfont{font-size: 28px;}
.pubSelt .ant-select-selection-placeholder{font-size: 14px;}
.pubSelt .ant-select-selection-item .iconfont{font-size: 18px;}

.uploadBox{height: calc(100% - 52px);display: flex;flex-wrap: wrap;align-items: flex-start;overflow: auto;}
.uploadBox::-webkit-scrollbar{display: none;}
.uploadBox .box .pubTit{margin-bottom: 30px;}
.uploadBox .box{width: 32%;border-radius: 2px;background-color: #fff;margin-right: 2%;padding-bottom: 15px;}
.uploadBox .box .flexCenter{padding: 0 24px;}
.uploadBox .box:nth-child(3n){margin-right: 0;}
.txtarea{flex: 1;height: 200px;}

.basiclistt .ant-image{width: 48px;height: 48px;}
.basiclistt .ant-image .ant-image-mask-info{font-size: 12px;}
.basiclistt .flex p{line-height: 18px;}

.basicBox .upbox{width: 120px;height: 120px;border-radius: 2px;border: 1px solid #E6E6E6;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;}
.basicBox .upbox .iconfont{font-size: 28px;}
.basicBox .upbox .zi{color: #666;margin-top: -4px;font-size: 14px;}
.basicBox .ant-image img,.basiclistt .ant-image img{height: 100%;}
.basicBox .ant-image .ant-image-mask-info,.basiclistt .ant-image .ant-image-mask-info{color: #fff;}
.basicBox .ant-form-item-label{width: 80px;text-align: left;}
.basicBox .ant-form{height: 0;flex: auto;display: flex;flex-direction: column;}
.basicBox .ant-form .dlbtn{margin-top: auto;}
.basicBox .picboxx{width: 120px;height: 120px;position: relative;cursor: pointer;}
.basicBox .picboxx>img{display: block;width: 100%;height: 100%;}
.basicBox .ylmask{position: absolute;left: 0;top: 0;right: 0;bottom: 0;background-color: rgba(0, 0, 0, .3);display: flex;justify-content: center;align-items: center;opacity: 0;;transition: .4s;}
.basicBox .ylmask .yl{color: #fff;cursor: pointer;}
.basicBox .picboxx:hover .ylmask{opacity: 1;}

.uploadlist{display: flex;flex-wrap: wrap;margin-top: 24px;padding-top: 16px;border-top: 1px solid #EFEFEF;min-height: 400px;align-content: start;margin-right: -24px;}
.uploadlist .item{width: 80px;margin-right: 20px;margin-bottom: 15px;cursor: pointer;height: 104px;}
// .uploadlist .item:nth-child(5n){margin-right: 0;}
.uploadlist .item .kk{width: 100%;height: 80px;border: 1px solid #E6E6E6;display: flex;align-items: center;justify-content: center;position: relative;}
.uploadlist .item .ant-image{height: 100%;}
.uploadlist .item img{display: block;width: 100%;height: 100%;user-select: none;}
.uploadlist .item p{font-size: 12px;color: #666;margin-top: 6px;text-align: center;line-height: 18px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.uploadlist .item .kk .iconfont{font-size: 68px;position: relative;}
.uploadlist .item .kk .sanj::after{display: block;content: '';width: 0;height: 0;border-bottom: 15px solid transparent;border-right: 15px solid transparent;position: absolute;right: 10.5px;top: 24.1px;}
.uploadlist .item .kk .icon-excelwenjian::after{border-bottom-color: #B7DFB9;}
.uploadlist .item .kk .icon-yasuobaowenjian::after{border-bottom-color: #BBC3D4;}
.uploadlist .item .kk .icon-shipinwenjian::after{border-bottom-color: rgb(202, 209, 248);}
.wjj::after{display: block;content: '';width: 56.5px;height: 34.3px;position: absolute;left: 5.5px;bottom: 29.5px;background-color: #ffca28;border-radius: 0 0 2px 2px;}
.uploadlist .item .kk .icon-PDFwenjian::after{border-bottom-color: #FDB8B8;}
.uploadlist .item .kk .icon-wordwenjian::after{border-bottom-color: #B5C8FC;}
.uploadlist .item .kk .icon-weizhiwenjian::after{border-bottom-color: rgb(234, 234, 234);}
.uploadlist .item .mask{position: absolute;left: 0;top: 0;right: 0;bottom: 0;background-color: rgba(0, 0, 0, .6);display: flex;align-items: center;justify-content: center;}
.uploadlist .item .mask .iconfont{font-size: 28px;color: #fff;}
.createfile{background-color: @success-color !important;margin-left: 20px;border-color: @success-color !important;}
.ant-image-preview-operations-operation{color: #fff;}
.ant-image-preview-operations-operation-disabled{color: rgba(255, 255, 255, 0.25);}

.uploadlist .item .ylmask{position: absolute;left: 0;top: 0;right: 0;bottom: 0;background: rgba(0, 0, 0, .3);display: none;justify-content: center;align-items: center;}
.uploadlist .item .yl{color: #fff;}
.uploadlist .item .kk:hover .ylmask{display: flex;}
.uploadlist .item .kk .closeIcon{position: absolute;top: -15px;right: -10px;font-size: 24px;z-index: 0;transition: .4s;display: none;}
.uploadlist .item .kk .closeIcon::after{display: block;content: '';width: 10px;height: 10px;background-color: #fff;position: absolute;left: 50%;top: 50%;margin: -5px 0 0 -5px;z-index: -1;}
.uploadlist .item .kk .closeIcon:hover{transform: rotate(180deg);}
.uploadlist .item .kk:hover .closeIcon{display: block;}

.ant-modal-title .flexCenter span{color: #999;}
.ant-modal-title .flexCenter .filename{cursor: pointer;color: #333;}

.myarea{display: flex;flex-direction: column;}
.myarea .bf-content{height: 0;flex: auto;}

.fullscreen .bf-controlbar{padding: 0 12px;}


.editMenu .ant-form-item-label{min-width: 78px;text-align: left;}



.qxlistt .ant-form-item-control-input-content{height: 300px;overflow: auto;}
.qxlistt .ant-form-item-control-input-content::-webkit-scrollbar{display: none;}
.qxlistt .ant-form-item-control-input{align-items: flex-start;}



.perlist{height: 0;flex: auto;border: 1px solid #EFEFEF;margin: 10px 24px 24px;white-space: nowrap;overflow: hidden;overflow-x: auto;}
.perlist .item{width: 270px;border-right: 1px solid #EFEFEF;height: 100%;display: inline-block;vertical-align: top;overflow-y: auto;}
.perlist .item::-webkit-scrollbar{display: none;}
.perlist .item .row{height: 52px;padding: 0 8px 0 36px;position: relative;cursor: pointer;}
.perlist .item .row .p1{flex: 1;font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.perlist .item .row .p1 span{color: #999;}
.perlist .item .row .iconfont{color: #666;cursor: pointer;padding: 0 7px;}
.perlist .item .row.active::before{display: block;content: "\e618";color: @primary-color;position: absolute;left: 12px;transform: rotate(90deg);}
.perlist .item .row.active .p1{color: @primary-color;}
.perlist .item:first-child .row.active::before{content: '';width: 4px;background-color: @primary-color;left: 0;top: 0;bottom: 0;transform: rotate(0deg);}
.perlist .item:first-child .row.active{background-color: var(--ant-primary-1);}
.perlist .item:first-child .row.active .p1{color: #333;}

.userInfo{
  display: flex;
  flex-direction: row;
}
.userNameInfo{
  margin-left: 10px;
  text-align: left;
}


.pubname span,.pubname p{cursor: pointer;}
.pubname span:hover,.pubname p:hover{color: @primary-color;text-decoration: underline;}

.ginfobox .ant-modal-content{background-color: transparent;box-shadow: none;}
.ginfobox .boximg{height: 700px;position: absolute;left: 0;top: 0;}
.ginfobox .wrapbox{width: 284px;height: 614px;margin: 14px auto 0;background-color: #000;overflow: auto;border-radius: 38px;position: relative;z-index: 9;}
.ginfobox .wrapbox::-webkit-scrollbar{display: none;}
.ginfobox .boxx{padding: 20px 15px;background-color: #fff;}
.ginfobox .name{font-size: 30px;color: #37322C;}
.ginfobox .p1{font-size: 14px;color: #666360;}
.ginfobox .bqlist .p1{display: flex;align-items: center;}
.ginfobox .bqlist .p1::after{display: block;content: '';width: 1px;height: 10px;background-color: #A5A7AD;margin: 0 10px;}
.ginfobox .bqlist .p1:last-child::after{display: none;}
.ginfobox .borderbot1{border-bottom: 1px solid #F2F7FB;margin: 0 -15px 10px;padding: 0 15px 10px;}
.ginfobox .t1{font-size: 16px;font-weight: bold;color: #37322C;display: flex;align-items: center;margin-bottom: 10px;}
.ginfobox .t1::before{display: block;content: '';width: 4px;height: 16px;background-color: #FF6900;margin-right: 10px;}
.ginfobox .marginl{margin-left: 14px;}
.ginfobox .t2{font-size: 15px;font-weight: bold;color: #666360;}
.ginfobox .p2{font-size: 12px;color: #A5A7AD;}
.ginfobox .jnbox{border: 1px solid #F2F7FB;}
.ginfobox .jnbox p{flex: 1;text-align: center;font-size: 14px;color: #666360;padding: 10px 0;}
.ginfobox .jnbox .flex{border-top: 1px solid #F2F7FB;}
.ginfobox .jnbox .head{background-color: #F2F7FB;border-top: 0;}
.ginfobox .bq{font-size: 14px;color: #666360;padding: 2px 4px;background-color: #F2F7FB;margin-right: 15px;margin-bottom: 10px;}
.ginfobox .imgbox{margin-right: -10px;}
.ginfobox .imgbox .ant-image{margin-right: 10px;}
.ginfobox .avatar{position: relative;width: 60px;height: 60px;}
.ginfobox .sex{width: 20px;height: 20px;border-radius: 100%;background-color: #F51056;border: 1px solid #F2F7FB;position: absolute;right: 0;bottom: 0;display: flex;align-items: center;justify-content: center;}
.ginfobox .sex img{display: block;width: 16px;}
.ginfobox .avatar .ant-image-img{border-radius: 100%;}
.ginfobox .p3{font-size: 16px;color: #1D1F24;}
.ginfobox .ovauu{overflow: auto;}
.ginfobox .ovauu::-webkit-scrollbar{display: none;}
.ginfobox .p4{font-size: 12px;color: #A5A7AD;}
.ginfobox .p5{font-size: 12px;color: #37322C;}
.ginfobox .zwlist .bq{padding: 4px 6px;}
.flex_imgbox{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}
.cfaDate{
  font-size: 10px;
}
.plitem{position: relative;padding-bottom: 30px;}
.plitem .rbox{flex: 1;margin-left: 10px;overflow: hidden;}

.bgfff{background-color: #fff !important;}
.padd{padding: 15px;border-radius: 6px;}


.content {
	display: flex;
}
.nan{
  background-color: #3234e9 !important;
}
.textLsjc {
	font-size: 14px;
	/* 设置多行文本溢出省略 */
	// display: -webkit-box;
	// /* 默认最多显示3行 */
	// -webkit-line-clamp: 3;
	// overflow: hidden;
	// -webkit-box-orient: vertical;
  height: 70px;
  overflow: hidden;
}

/* 按钮右下角文字环绕布局 */
/* 设置伪元素是为了将按钮挤到底部 */
.textLsjc::before {
	content: '';
	float: right;
	width: 0;
	/* 因为text的height是动态改变的，所以得设置text父元素flex布局，否则下面的height用100%设置无效*/
	/* 19px为按钮文字高度 */
	/* height: calc(100% - 19px); */
	/* margin实现，性能比calc要好 */
	height: 100%;
	margin-bottom: -19px;
}

.jyfwbtn {
	float: right;
	clear: both;
	cursor: pointer;
	color: #FF6900;
	background: #FFF1E8;
	line-height: 20px;
	padding: 0px 6px;font-size: 12px;position: absolute;bottom: 5px;left: 58px;
}

/* 伪元素设置按钮文本*/
.jyfwbtn::after {
	content: '展开全部';
}

/* 隐藏checkbox */
.exp {
	display: none;
}

/* 通过兄弟选择器设置展开后的样式 */
.exp:checked + .textLsjc {
	/* 展开设置一个足够大的行数 */
	-webkit-line-clamp: 999;
  height: auto;
}

.exp:checked + .textLsjc .jyfwbtn::after {
	content: '收起';
}
.AppHead{
  height: 30px;
  text-align: center;
}
.AppHeadTitle{
  font-size: 18px;
  font-weight: bold;
}
.imgsDiv .ant-image{margin-right: 10px;}



.bannerInfo .pic{display: block;width: 100%;}
.takbox{
  width: 120px;height: 120px;border: 1px solid #E6E6E6;cursor: pointer;position: relative;
  span{display: block;font-size: 12px;text-align: center;color: #FE6737;}
  .iconfont{font-size: 28px;text-align: center;color: #333;margin: 9px 0 0;}
  p{color: #666;text-align: center;margin-bottom: 3px;}
  .close{position: absolute;left: 0;right: 0;bottom: 0;text-align: center;line-height: 24px;font-size: 12px;background-color: rgb(254 103 55 / 80%);color: #fff;opacity: 0;transition: .4s;}
}
.takbox:hover .close{opacity: 1;}

.pubList .ant-image-mask-info .anticon-eye{display: none;}
.pubList .ant-image-mask-info{color: #fff;}



